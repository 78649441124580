<template>
  <div class="faq__item">
    <div
      @click="show = !show"
      :tabindex="tabindex"
      class="border-item d-flex align-items-center position-relative"
      :class="show ? 'border-item-active' : ''"
    >
      <p class="f-w6 text-left span mb-0">{{ question }}</p>
      <img
        class="btn-down"
        src="../../assets/image/arrow-down.svg"
        alt="down"
        :class="show ? 'btn-up' : ''"
      />
    </div>
    <div v-if="show" class="show-item text-left">
      <p class="f-w3 mb-0" v-html="answer"></p>
      <div class="text-center">
        <img
          class="mt-3 faq-image"
          v-if="imageSrc != 'null'"
          :src="require('@/assets/image/faq/' + imageSrc)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQItem",
  props: {
    question: {
      type: String
    },
    answer: {
      type: String
    },
    tabindex: {
      type: Number
    },
    imageSrc: {
      type: String,
      default: "null"
    }
  },
  data() {
    return {
      show: false
    };
  }
};
</script>

<style lang="scss" scoped>
.faq-image {
  max-width: 100%;
}
.faq__item /deep/ span {
  color: #eb5050;
}
.faq__item /deep/ .text-bold {
  font-weight: bold;
}
.faq__item /deep/ .row {
  margin-left: 0;
  margin-right: 0;
  align-items: center;
}
.faq__item /deep/ .col-6,
.faq__item /deep/.col-2,
.faq__item /deep/.col-4 {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 10px;
  text-align: left;
}
.faq__item /deep/.col-2 {
  text-align: center;
}
.border-item-active {
  height: 39px;
  border: solid 1px #0178fd;
  background-color: #ecf5ff;
  border-radius: 5px 5px 0 0 !important;
  cursor: pointer;
}

.border-item {
  height: 39px;
  border: solid 1px #0178fd;
  background-color: #ecf5ff;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  outline: none;
  padding-left: 10px;
  padding-right: 30px;

  .span {
    color: #0178fd;
    font-size: 14px;
    line-height: 1.5;
  }

  .btn-down {
    position: absolute;
    right: 18px;
  }

  .btn-up {
    position: absolute;
    right: 18px;
    transform: rotate(180deg);
  }
}

.show-item {
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
  border-bottom: solid 1px #0178fd;
  border-right: solid 1px #0178fd;
  border-left: solid 1px #0178fd;
  padding: 18px 10px 18px 10px;
  min-height: 53px;
  font-size: 14px;
  line-height: 1.3;
  pre {
    white-space: break-spaces;
  }
}
</style>
