<template>
  <div id="FAQ">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="content">
        <div class="row">
          <div class="col-xl-6">
            <template v-if="authUser.sex == 1">
              <FAQItem
                :question="recruitment.name"
                :answer="recruitment.answer"
                :tabindex="1"
                :imageSrc="recruitment.imageSrc"
              />
            </template>
            <template v-if="authUser.sex == 2">
              <FAQItem
                :question="recruitmentCast.name"
                :answer="recruitmentCast.answer"
                :tabindex="1"
                :imageSrc="recruitment.imageSrc"
              />
            </template>
            <p class="text-left f-w6 span-pc ml-3">
              〇ゴルフレベル・ランクについて
            </p>
            <template v-if="authUser.sex == 1">
              <FAQItem
                v-for="(item, key) of rank"
                :key="key + 'rank'"
                :tabindex="key"
                :question="item.name"
                :answer="item.answer"
                :imageSrc="item.imageSrc"
              />
            </template>
            <template v-if="authUser.sex == 2">
              <FAQItem
                v-for="(item, key) of rankCast"
                :key="key + 'rank'"
                :tabindex="key"
                :question="item.name"
                :answer="item.answer"
                :imageSrc="item.imageSrc"
              />
            </template>
            <p class="text-left f-w6 span-pc ml-3">〇ポイントについて</p>
            <template v-if="authUser.sex == 1">
              <FAQItem
                v-for="(item, key) of score"
                :key="key + 'message'"
                :tabindex="key"
                :question="item.name"
                :answer="item.answer"
                :imageSrc="item.imageSrc"
              />
            </template>
            <template v-if="authUser.sex == 2">
              <FAQItem
                v-for="(item, key) of scoreCast"
                :key="key + 'message'"
                :tabindex="key"
                :question="item.name"
                :answer="item.answer"
                :imageSrc="item.imageSrc"
              />
            </template>
          </div>
          <div class="col-xl-6">
            <p class="text-left f-w6 p-pc ml-3">〇メッセージについて</p>
            <p class="text-left f-w3 span-note mb-0">
              男性・女性ユーザー共に、男女間でのメッセージのやり取りが可能になっております。
            </p>
            <p class="text-left f-w3 span-note">
              但し、男性から男性、女性から女性へのメッセージのやり取りはできませんのでご了承ください。
            </p>
            <FAQItem
              v-for="(item, key) of message"
              :key="key + 'score'"
              :tabindex="key"
              :question="item.name"
              :answer="item.answer"
              :imageSrc="item.imageSrc"
            />
            <p class="text-left f-w6 span-pc ml-3">〇キャンセルについて</p>
            <template v-if="authUser.sex == 1">
              <FAQItem
                v-for="(item, key) of cancel"
                :key="key + 'cancel'"
                :tabindex="key"
                :question="item.name"
                :answer="item.answer"
                :imageSrc="item.imageSrc"
              />
            </template>
            <template v-if="authUser.sex == 2">
              <FAQItem
                v-for="(item, key) of cancelCast"
                :key="key + 'cancel'"
                :tabindex="key"
                :question="item.name"
                :answer="item.answer"
                :imageSrc="item.imageSrc"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>
<script>
import CardFooter from "@/components/CardFooter";
import CardHeader from "@/components/CardHeader";
import FAQItem from "@/views/Common/FAQItem";
import { mapGetters } from "vuex";
export default {
  components: {
    FAQItem,
    CardHeader,
    CardFooter
  },
  metaInfo() {
    return {
      title: "よくあるご質問",
      titleTemplate: "%s | PreGo"
    };
  },

  computed: {
    ...mapGetters({
      authUser: "auth/user"
    })
  },
  data() {
    return {
      headerTitle: {
        text: "よくあるご質問",
        isShowTitle: true,
        isShowLogo: false
      },
      showItemOrder: false,
      showItemRank: false,
      recruitmentCast: {
        name: "Superオファーとは",
        answer:
          "<p>男性ユーザーがラウンド日時を設定し、女性ユーザーに対しSuperオファーをかけることを言います。</p>" +
          "<p>Superオファー作成日から45日先の日時でSuperオファーがかけられます。</p>" +
          "男性ユーザー指定日の前日18時までに女性ユーザーからの応募がなければ、自動的にSuperオファーはオートキャンセルされます。",
        imageSrc: "null"
      },

      recruitment: {
        name: "Superオファーとは",
        answer:
          "<p>ラウンド日時を設定し、女性ユーザーに対しSuperオファーをかけることを言います。</p>" +
          "<p>Superオファー作成日から45日先の日時でSuperオファーを出すことができます。</p>" +
          "男性ユーザー指定日の前日18時までに女性ユーザーからの応募がなければ、自動的にSuperオファーはオートキャンセルされます。ポイントの消費もありません。",
        imageSrc: "null"
      },

      rank: [
        {
          name: "ゴルフレベルとは",
          answer:
            "ご登録頂いたベストスコア、アベレージスコアを基準に、利用回数等で算出したレベルのことを言います。<br>",
          imageSrc: "rank-faq.png"
        },
        {
          name: "ランクとは",
          answer:
            "<p>男性・女性ユーザーはご利用実績（利用回数、頻度、内容）によって設定されています。</p> ランクが高いと信用度が高くなり、マッチングしやすくなります。",
          imageSrc: "faq-prego.png"
        },
        {
          name: "マッチングの流れ",
          imageSrc: "null",
          answer:
            "<p>マッチングは「通常マッチング」、「オファーマッチング」の二種類があります。</p><p>「通常マッチング」は男性ユーザーがSuperオファーを出し、女性ユーザーがSuperオファー広告を見て応募し、男性ユーザーが承認すればマッチングされることを言います。</p>「オファーマッチング」は男性ユーザーが女性ユーザーに対し、直接オファー（ゴルフのお誘い）を出し、女性ユーザーが承認すればマッチングされることを言います。"
        },
        {
          name: "オファーとはなんですか？",
          imageSrc: "null",
          answer:
            "男性ユーザーが女性ユーザーに対し、直接オファー（ゴルフのお誘い）をすることを言います。"
        },
        {
          name: "安全面は大丈夫ですか？",
          imageSrc: "null",
          answer:
            "<p>男女共に登録時、プロフィール写真の審査、公的身分証の提出を行っているため安心してラウンド相手を探して頂けます。</p> <p>監視体制も整えており、お問い合わせ対応も行っております。</p><p>許認可</p><p>インターネット異性紹介事業　届出済み　受理番号：3021-0052-000</p>電子通信事業　届出済み　届出番号：Ａ-03-18861"
        },
        {
          name: "合流方法について",
          imageSrc: "null",
          answer:
            "<p>「ピックアップ」とは男性ユーザーが女性ユーザーを迎えに行き、現地に向かうことを言います。</p>" +
            "<p>「現地集合」とはゴルフ場で直接待ち合わせすることを言います。</p>" +
            "<p>男性ユーザーは「ピックアップ」または「現地集合」の選択を「Superオファー」、「オファー」を出す際に選択できます。 </p>" +
            "女性ユーザーに「ピックアップ」をして欲しい場所をメッセージで確認しておくとスムーズにマッチングまで進むこともできます。家の住所を聞くことはしないでください。ご注意ください"
        },
        {
          name: "ゴルフ場のプレーフィーについて",
          imageSrc: "null",
          answer:
            "利用規約にも記載があるように、ゴルフ場のプレーフィーは男性ユーザー様にお支払いいただくことになっております。あらかじめご了承ください。"
        },
        {
          name: "ゴルフ場の変更について",
          imageSrc: "null",
          answer:
            "「通常マッチング」、「オファーマッチング」後、ゴルフ場の変更を行いたい場合は、マッチングした女性ユーザーとメッセージでやり取りを行い、ゴルフ場を変更してください。"
        }
      ],
      rankCast: [
        {
          name: "ゴルフレベルとは",
          answer:
            "ご登録頂いたベストスコア、アベレージスコアを基準に、利用回数等で算出したレベルのことを言います。<br>",
          imageSrc: "rank-faq.png"
        },
        {
          name: "ランクとは",
          answer:
            "<p>男性・女性ユーザーはご利用実績（利用回数、頻度、内容）によって設定されています。</p> ランクが高いと信用度が高くなり、マッチングしやすくなります。",
          imageSrc: "faq-prego.png"
        },
        {
          name: "マッチングの流れ",
          imageSrc: "null",
          answer:
            "<p>マッチングは「通常マッチング」、「オファーマッチング」の二種類があります。</p><p>「通常マッチング」は男性ユーザーがSuperオファーを出し、女性ユーザーがSuperオファー広告を見て応募し、男性ユーザーが承認すればマッチングされることを言います。</p>「オファーマッチング」は男性ユーザーが女性ユーザーに対し、直接オファー（ゴルフのお誘い）を出し、女性ユーザーが承認すればマッチングされることを言います。"
        },
        {
          name: "オファーとはなんですか？",
          imageSrc: "null",
          answer:
            "男性ユーザーが女性ユーザーに対し、直接オファー（ゴルフのお誘い）をすることを言います。"
        },
        {
          name: "安全面は大丈夫ですか？",
          imageSrc: "null",
          answer:
            "<p>男女共に登録時、プロフィール写真の審査、公的身分証の提出を行っているため安心してラウンド相手を探して頂けます。</p> <p>監視体制も整えており、お問い合わせ対応も行っております。</p><p>許認可</p><p>インターネット異性紹介事業　届出済み　受理番号：3021-0052-000</p>電子通信事業　届出済み　届出番号：Ａ-03-18861"
        },
        {
          name: "合流方法について",
          imageSrc: "null",
          answer:
            "<p>「ピックアップ」とは男性ユーザーが女性ユーザーを迎えに行き、現地に向かうことを言います。</p>" +
            "<p>「現地集合」とはゴルフ場で直接待ち合わせすることを言います。</p>" +
            "<p>男性ユーザーは「ピックアップ」または「現地集合」の選択を「Superオファー」、「オファー」を出す際に選択できます。 </p>" +
            "女性ユーザーは「ピックアップ」をして欲しい場所をあらかじめメッセージで伝えておくとスムーズにマッチングまで進むこともできます。家の住所を伝えることはお勧めしませんのでご注意ください。"
        },
        {
          name: "ゴルフ場のプレーフィーについて",
          imageSrc: "null",
          answer:
            "利用規約にも記載があるように、ゴルフ場のプレーフィーは男性ユーザー様にお支払いいただくことになっております。あらかじめご了承ください。"
        },
        {
          name: "ゴルフ場の変更について",
          imageSrc: "null",
          answer:
            "「通常マッチング」、「オファーマッチング」後、ゴルフ場の変更を行いたい場合は、マッチングしたユーザーとメッセージでやり取りを行い、ゴルフ場を変更してください。"
        }
      ],
      score: [
        {
          name: "ポイントの購入の仕方",
          imageSrc: "null",
          answer:
            "<p>マイページの「ポイント購入」にて、ご購入が可能です。</p>" +
            "<p>ご登録頂いたクレジットカードで購入金額が決済されます。</p>" +
            "<p>マイページの「プロフィール編集」からご登録頂いたクレジットカードの変更も可能です。</p>" +
            "<p>ポイントは購入しなくてもSuperオファーやオファーを出すことが可能です。</p>" +
            "マッチングが成立した際に、ご登録頂いたクレジットカードで購入金額が決済されます。"
        },
        {
          name: "オートチャージについて",
          imageSrc: "null",
          answer:
            "<p>ポイントは購入しなくてもSuperオファーやオファーを出すことが可能です。</p>" +
            "マッチングが成立した際に、ご登録頂いたクレジットカードで購入金額が決済されます。"
        },
        {
          name: "ポイントの有効期限について",
          imageSrc: "null",
          answer:
            "<p>ポイントには有効期限が設定されています。</p>" +
            "<p>ご購入いただいたポイントは、ご購入日を含め150日間の有効期限があります。</p>" +
            "<p>150日間の期限が過ぎますと、自動的にポイントは消滅します。</p>" +
            "保有ポイントの確認方法としましては、マイページの「ポイント購入」にて保有しているポイントをご確認頂けます。"
        },
        {
          name: "ポイントの払い戻しについて",
          imageSrc: "null",
          answer:
            "<p>ポイントの払い戻しは行っておりません。</p>" +
            "ご購入いただく際は、ご注意ください。"
        }
      ],
      scoreCast: [
        {
          name: "ポイントの購入の仕方",
          imageSrc: "null",
          answer:
            "<p>男性ユーザーはマイページの「ポイント購入」にて、ご購入が可能です。</p>" +
            "<p>ご登録頂いたクレジットカードで購入金額が決済されます。</p>" +
            "<p>マイページの「プロフィール編集」からご登録頂いたクレジットカードの変更も可能です。</p>" +
            "<p>ポイントは購入しなくてもSuperオファーやオファーを出すことが可能です。</p>" +
            "マッチングが成立した際に、ご登録頂いたクレジットカードで購入金額が決済されます。"
        },
        {
          name: "ポイントの有効期限について",
          imageSrc: "null",
          answer:
            "<p>ポイントには有効期限が設定されています。</p>" +
            "<p>ご購入いただいたポイントは、ご購入日を含め150日間の有効期限があります。</p>" +
            "<p>150日間の期限が過ぎますと、自動的にポイントは消滅します。</p>" +
            "保有ポイントの確認方法としましては、マイページの「ポイント購入」にて保有しているポイントをご確認頂けます。"
        },
        {
          name: "ポイントの払い戻しについて",
          imageSrc: "null",
          answer:
            "<p>ポイントの払い戻しは行っておりません。</p>" +
            "ご購入いただく際は、ご注意ください。"
        }
      ],

      message: [
        {
          name: "メッセージで誹謗中傷があった場合について",
          imageSrc: "null",
          answer:
            "<p>誹謗中傷や不快があった場合は、相手のプロフィールページの右上にある「…」でブロックすることが出来ます。そちらをお使いください。</p>" +
            "<p>ブロックリストに追加すると、双方のメッセージ履歴は消去されます。</p>" +
            "ブロックリストはマイページの「ブロック」から確認、解除することもできます。"
        }
      ],
      cancel: [
        {
          name: "キャンセル料について",
          imageSrc: "faq-cancel.png",
          answer:
            '<p class="text-bold">キャンセル料は<span>マッチング成立後</span>のキャンセルで発生します。</p>' +
            '<div class="row"><div class="col-6">ラウンド日　2日前0時から前日18時まで</div><div class="col-2">→</div><div class="col-4">キャンセル料100% </div></div>' +
            '<div class="row"><div class="col-6">ラウンド日　7日前0時から２日前0時まで</div><div class="col-2">→</div><div class="col-4">キャンセル料50% </div></div>' +
            '<div class="row"><div class="col-6">ラウンド日　8日前まで</div><div class="col-2">→</div><div class="col-4">キャンセル料  0%</div></div>' +
            '<div class="row"><div class="col-6">ラウンゴルフ場側のクローズの場合</div><div class="col-2">→</div><div class="col-4">キャンセル料  0%</div></div>'
        },
        {
          name: "Superオファーのキャンセル・オートキャンセルについて",
          imageSrc: "null",
          answer:
            "<p>Superオファー作成後、女性ユーザーからエントリーが無かった場合、ラウンド指定日の前日18：00にオートキャンセルされます。自らキャンセルすることも可能です。</p>" +
            "<p>エントリーがあった場合でもマッチングを確定しない場合は同様にオートキャンセルされ、自らキャンセルすることも可能です。</p>" +
            "<p>上記ケースの場合、キャンセル料は発生いたしません。</p>" +
            "<p>ご自身でキャンセルする場合は、マイページの「Superオファー状況」→「指定のSuperオファー」→「詳細」から行えます。</p>" +
            "<p>やむを得ない事由によってキャンセルしたい方はこちらまでご相談ください。</p>" +
            "<p>体調不良や急用等でのキャンセルは全て有料キャンセルの対象となりますのでご了承ください。</p>" +
            "キャンセルを申請していただく際にはメッセージの内容も含め添付していただけますと迅速に対応が行えます。何卒、宜しくお願い致します。"
        },
        {
          name: "足あとについて",
          imageSrc: "null",
          answer: "自分の詳細プロフィールを訪れてくれた方の訪問履歴です。"
        },
        {
          name: "通知について",
          imageSrc: "null",
          answer:
            "マイページの「通知設定」により、通知ＯＮ・ＯＦＦの設定が行えます。"
        },
        {
          name: "領収書について",
          imageSrc: "null",
          answer:
            "領収書が必要な際は、マイページの「ポイント履歴」から、ポイントをご購入して頂いた日付で領収書をPDFで発行しております。"
        },
        {
          name: "個人情報の表示について",
          imageSrc: "null",
          answer:
            "マイページから「ヘルプ」→「プライバシーポリシー」記載の通り、個人情報保護法に遵守し、登録者様の名前、電話番号、住所、生年月日、登録身分証などの個人情報は相手方には非表示になっております。"
        },
        {
          name: "お問い合わせについて",
          imageSrc: "null",
          answer:
            "お問い合わせ先  " +
            "<a href='info@prego.golf' style='text-decoration: none; pointer-events: none'>info@prego.golf</a>" +
            "<br>" +
            "連絡先電話番号については、上記のお問い合わせフォームでご請求いただければ、遅滞なく開示いたします。キャンセルを申請していただく際にはメッセージの内容や電話番号も含め添付していただけますと迅速に対応が行えます。何卒、宜しくお願い致します。"
        }
      ],
      cancelCast: [
        {
          name: "キャンセル料について",
          imageSrc: "faq-cancel.png",
          answer:
            '<p class="text-bold">キャンセル料は<span>マッチング成立後</span>のキャンセルで発生します。</p>' +
            '<div class="row"><div class="col-6">ラウンド日　2日前0時から前日18時まで</div><div class="col-2">→</div><div class="col-4">キャンセル料100% </div></div>' +
            '<div class="row"><div class="col-6">ラウンド日　7日前0時から２日前0時まで</div><div class="col-2">→</div><div class="col-4">キャンセル料50% </div></div>' +
            '<div class="row"><div class="col-6">ラウンド日　8日前まで</div><div class="col-2">→</div><div class="col-4">キャンセル料  0%</div></div>' +
            '<div class="row"><div class="col-6">ラウンゴルフ場側のクローズの場合</div><div class="col-2">→</div><div class="col-4">キャンセル料  0%</div></div>'
        },
        {
          name: "Superオファーのキャンセル・オートキャンセルについて",
          imageSrc: "null",
          answer:
            "<p>Superオファー作成後、女性ユーザーからエントリーが無かった場合、ラウンド指定日の前日18：00にオートキャンセルされます。自らキャンセルすることも可能です。</p>" +
            "<p>エントリーがあった場合でもマッチングを確定しない場合は同様にオートキャンセルされ、自らキャンセルすることも可能です。</p>" +
            "<p>上記ケースの場合、キャンセル料は発生いたしません。</p>" +
            "<p>ご自身でキャンセルする場合は、マイページの「Superオファー状況」→「指定のSuperオファー」→「詳細」から行えます。</p>" +
            "<p>やむを得ない事由によってキャンセルしたい方はこちらまでご相談ください。</p>" +
            "<p>体調不良や急用等でのキャンセルは全て有料キャンセルの対象となりますのでご了承ください。</p>" +
            "キャンセルを申請していただく際にはメッセージの内容も含め添付していただけますと迅速に対応が行えます。何卒、宜しくお願い致します。"
        },
        {
          name: "コインについて",
          imageSrc: "null",
          answer:
            "男性ユーザーのSuperオファーに対して受付する際に、獲得することのできるコインが表示されております。"
        },
        {
          name: "コインの支払いについて",
          imageSrc: "null",
          answer:
            "<p>コインは「マイページ」の「コイン履歴」から獲得履歴、コインの残高が確認できます。</p>" +
            "<p>コインはプレゴに出金申請を行い、1コイン=1円で獲得したコインを出金することが出来ます。登録した口座に月末締め、翌月25日までにお支払いさせていただきます。</p>" +
            "振込手数料と源泉徴収税額を引いた金額でお支払いをさせていただきますので、ご了承の程、宜しくお願い致します。"
        },
        {
          name: "コイン獲得の内訳",
          imageSrc: "null",
          answer:
            "<p>個人オファーでマッチング後、ゴルフに行けた場合は8,000コイン以上が獲得できます。</p>" +
            "<p>現地集合でマッチング後、ゴルフに行けた場合は10,000コイン以上が獲得できます。</p>" +
            "獲得ポイントの反映はプレー日の翌日以降の反映になります。"
        },
        {
          name: "ランクについて",
          imageSrc: "null",
          answer:
            "<p>女性ユーザーはご利用実績（利用回数、頻度、内容）によって設定されています。</p>" +
            "<p>ランクが高いと信用度が高くなり、マッチングしやすくなります。</p>" +
            "<span class='mb-0'>女性はランクが高いと信用度が高くなり、獲得コインが多く頂けるようになります。</span>"
        },
        {
          name: "足あとについて",
          imageSrc: "null",
          answer: "自分の詳細プロフィールを訪れてくれた方の訪問履歴です。"
        },
        {
          name: "通知について",
          imageSrc: "null",
          answer:
            "マイページの「通知設定」により、通知ＯＮ・ＯＦＦの設定が行えます。"
        },
        {
          name: "領収書について",
          imageSrc: "null",
          answer:
            "マイページの「通知設定」により、通知ＯＮ・ＯＦＦの設定が行えます。"
        },
        {
          name: "個人情報の表示について",
          imageSrc: "null",
          answer:
            "マイページから「ヘルプ」→「プライバシーポリシー」記載の通り、個人情報保護法に遵守し、登録者様の名前、電話番号、住所、生年月日、登録身分証などの個人情報は相手方には非表示になっております。"
        },
        {
          name: "お問い合わせについて",
          imageSrc: "null",
          answer:
            "お問い合わせ先  " +
            "<a href='info@prego.golf' style='text-decoration: none; pointer-events: none'>info@prego.golf</a>" +
            "<br>" +
            "連絡先電話番号については、上記のお問い合わせフォームでご請求いただければ、遅滞なく開示いたします。キャンセルを申請していただく際にはメッセージの内容や電話番号も含め添付していただけますと迅速に対応が行えます。何卒、宜しくお願い致します。"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/FAQ.scss";
@import "~@/assets/scss/_fontFamily.scss";
.span-note {
  font-size: 14px !important;
  margin-top: 0 !important;
}

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   // height: calc(100vh - 152px);
  // }
}
</style>
